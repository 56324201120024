import { createContext, useState, useContext } from 'react';
const OpenCloseComponentContext = createContext();

function OpenCloseComponentContextProvider({ children }) {
  const [showAccountOptions, setShowAccountOptions] = useState(true);
  return (
    <OpenCloseComponentContext.Provider
      value={{ showAccountOptions, setShowAccountOptions }}
    >
      {children}
    </OpenCloseComponentContext.Provider>
  );
}
const useOpenCloseComponent = () => {
  const ctx = useContext(OpenCloseComponentContext);
  return ctx;
};
export default OpenCloseComponentContextProvider;
export { OpenCloseComponentContext, useOpenCloseComponent };
