import React, { useState } from 'react';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  const handleError = (error, errorInfo) => {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return (
    <React.Suspense
      fallback={<h1>Loading...</h1>} // Optional fallback if using Suspense for lazy-loaded components
    >
      {React.cloneElement(children, { onError: handleError })}
    </React.Suspense>
  );
}

export default ErrorBoundary;
