import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { AuthContext } from '../context/AuthContext';
import TawkToManager from '../components/chat/TawkToManager';
import { getCsrfToken } from '../services/csrfService';
import axios from '../../src/config/axios';
import CommonLayOut from '../components/layout/CommonLayOut';
//----------------------------------------------------------------------------
const CartPage = lazy(() => import('../pages/CartPage'));
const CategoryPage = lazy(() => import('../pages/DynamicCategoryPage'));
const DynamicCategoryPage = lazy(() => import('../pages/DynamicCategoryPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const SignUpPage = lazy(() => import('../pages/SignUpPage'));
const ProductPage = lazy(() => import('../pages/ProductPage'));
const DynamicDetailPage = lazy(() => import('../pages/DynamicDetailPage'));
const DynamicCartPage = lazy(() => import('../pages/DynamicCartPage'));
const DynamicPaymentPage = lazy(() => import('../pages/DynamicPaymentPage'));
const ContactPage = lazy(() => import('../pages/ContactPage'));
const OrderPage = lazy(() => import('../pages/OrderPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));
const DynamicAddProductAdminPage = lazy(() =>
  import('../pages/DynamicAddProductAdminPage')
);
const OrderAdminPage = lazy(() => import('../pages/OrderAdminPage'));
const AddMultipleProductsAdminPage = lazy(() =>
  import('../pages/AddMultipleProductsAdminPage')
);
const OrderAdminDetailPage = lazy(() =>
  import('../pages/OrderAdminDetailPage')
);
const DemoAddProductAdminPage = lazy(() =>
  import('../pages/DemoAddProductAdminPage')
);
const DynamicEditSelectedProductPage = lazy(() =>
  import('../pages/DynamicEditSelectedProductPage')
);
const TransactionReportPage = lazy(() =>
  import('../pages/TransactionReportPage')
);
const BillingInvoiceReportPage = lazy(() =>
  import('../pages/BillingInvoiceReportPage')
);
const CreateBillingInvoicePage = lazy(() =>
  import('../pages/CreateBillingInvoicePage')
);
const BillingInvoiceDetailPage = lazy(() =>
  import('../pages/BillingInvoiceDetailPage')
);
const PurchaseOrderReportPage = lazy(() =>
  import('../pages/PurchaseOrderReportPage')
);
const PurchaseOrderDetailPage = lazy(() =>
  import('../pages/PurchaseOrderDetailPage')
);
const UserOrderReportPage = lazy(() => import('../pages/UserOrderReportPage'));
const UserOrderDetailPage = lazy(() => import('../pages/UserOrderDetailPage'));
const UserOrderConfirmedPage = lazy(() =>
  import('../pages/UserOrderConfirmedPage')
);
const DynamicProductMovementPage = lazy(() =>
  import('../pages/DynamicProductMovementPage')
);
const SupplierBillingInvoiceReportPage = lazy(() =>
  import('../pages/SupplierBillingInvoiceReportPage')
);
const SupplierBillingInvoiceDetailPage = lazy(() =>
  import('../pages/SupplierBillingInvoiceDetailPage')
);
const SaleTaxInvoiceReportPage = lazy(() =>
  import('../pages/SaleTaxInvoiceReportPage')
);
const SaleTaxInvoiceDetailPage = lazy(() =>
  import('../pages/SaleTaxInvoiceDetailPage')
);
const OtpPage = lazy(() => import('../pages/OtpPage'));
const OtpPhoneNumberPage = lazy(() => import('../pages/OtpPhoneNumberPage'));

const ChartPage = lazy(() => import('../pages/ChartPage'));

function Router() {
  const { role, setRole } = useContext(AuthContext);
  const location = useLocation();
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();

  const routerCheckAuthStatus = async () => {
    try {
      const csrfTokenFromService = await getCsrfToken(); // Ensure you have a method to get the CSRF token
      // console.log('csrfTokenFromService', csrfTokenFromService);
      const resIsValidRefreshToken = await axios.get(
        '/auth/checkRefreshToken',
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfTokenFromService,
          },
        }
      );
      if (resIsValidRefreshToken?.data?.isValid === false) {
        setRole(null);
      }
      const resIsValidAdminRefreshToken = await axios.get(
        '/auth/checkAdminRefreshToken',
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfTokenFromService,
          },
        }
      );
      if (resIsValidAdminRefreshToken?.data?.isValid === false) {
        setRole(null);
      }
      if (resIsValidRefreshToken?.data?.isValid === true) {
        const res = await axios.post(
          '/auth/refreshAccessToken',
          {},
          {
            withCredentials: true,
            headers: {
              'CSRF-Token': csrfTokenFromService,
            },
          }
        );
        if (res?.status === 200 || res?.status === 201) {
          const resMe = await axios.get('/users/me');
          // console.log('resMe?.data?', resMe?.data);
          setRole(resMe?.data?.role);
          setIsAuthChecked(true);
        } else {
        }
      }
      if (resIsValidAdminRefreshToken?.data?.isValid === true) {
        const res = await axios.post(
          '/auth/refreshAdminAccessToken',
          {},
          {
            withCredentials: true,
            headers: {
              'CSRF-Token': csrfTokenFromService,
            },
          }
        );
        if (res?.status === 200 || res?.status === 201) {
          const resAdmin = await axios.get('/admins/admin', {
            withCredentials: true,
            headers: {
              'CSRF-Token': csrfTokenFromService,
            },
          });
          setRole(resAdmin?.data?.role);
          setIsAuthChecked(true);
        } else {
          setRole(null);
        }
      }
    } catch (refreshError) {
      console.log('Failed to refresh access token', refreshError);
    } finally {
      setIsAuthChecked(true);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('lastPath', currentPath);
    routerCheckAuthStatus();
  }, []);

  useEffect(() => {
    const lastPath = localStorage.getItem('lastPath');
    if (role !== null && isAuthChecked === true && lastPath) {
      navigate(lastPath);
      setIsAuthChecked(false);
    }
  }, [role, isAuthChecked]);

  return (
    <Suspense
      fallback={
        <div
          id='suspenseFallback'
          className='h-screen w-screen flex justify-center items-center'
        >
          <dotlottie-player
            src='https://lottie.host/5892a31a-f3b8-4f9d-a8c9-25af7638ee75/8JV2xJwuwt.json'
            background='transparent'
            speed='1'
            style={{ width: '300px', height: '300px' }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
      }
    >
      <TawkToManager />
      <Routes className=''>
        <Route path='/chart/panit' element={<ChartPage />}></Route>
        <Route path='/' element={<CommonLayOut />}>
          <Route path='' element={<HomePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='otp_phoneNumber' element={<OtpPhoneNumberPage />} />

          {!role ? (
            <>
              <Route path='login' element={<LoginPage />} />
              <Route path='signup' element={<SignUpPage />} />
              <Route path='otp' element={<OtpPage />} />
            </>
          ) : (
            <></>
          )}
          <Route path='category' element={<CategoryPage />} />
          <Route
            path='category/:categoryName'
            element={<DynamicCategoryPage />}
          />
          <Route path='product' element={<ProductPage />} />
          <Route
            path='product/:productName/:productId'
            element={<DynamicDetailPage />}
          />
          {role === 'admin' || role === 'saleAdmin' ? (
            <>
              {role === 'admin' ? (
                <>
                  <Route
                    path='dynamicAddProductAdmin'
                    element={<DynamicAddProductAdminPage />}
                  />
                  <Route
                    path='addMultipleProductsAdmin'
                    element={<AddMultipleProductsAdminPage />}
                  />
                  <Route
                    path='transaction-report'
                    element={<TransactionReportPage />}
                  />
                  <Route
                    path='purchase_order/:purchaseOrderId'
                    element={<PurchaseOrderDetailPage />}
                  />
                  <Route
                    path='purchase_order_report'
                    element={<PurchaseOrderReportPage />}
                  />
                  <Route
                    path='product_movement_report/:productId'
                    element={<DynamicProductMovementPage />}
                  />
                </>
              ) : (
                <></>
              )}
              <Route
                path='addProductAdmin'
                element={<DemoAddProductAdminPage />}
              />
              <Route path='order_admin' element={<OrderAdminPage />} />
              <Route
                path='order_admin/:orderId'
                element={<OrderAdminDetailPage />}
              />
              <Route
                path='updateProductAdmin'
                element={<DynamicEditSelectedProductPage />}
              />
              <Route
                path='billing-invoice-report'
                element={<BillingInvoiceReportPage />}
              />
              <Route
                path='supplier-billing-invoice-report'
                element={<SupplierBillingInvoiceReportPage />}
              />
              <Route
                path='supplier-billing-invoice/:supplierBillingInvoiceId'
                element={<SupplierBillingInvoiceDetailPage />}
              />
              <Route
                path='create-billing-invoice'
                element={<CreateBillingInvoicePage />}
              />
              <Route
                path='billing-invoice/:billingInvoiceId'
                element={<BillingInvoiceDetailPage />}
              />
              <Route
                path='sale_tax_invoice_report'
                element={<SaleTaxInvoiceReportPage />}
              />
              <Route
                path='sale_tax_invoice/:saleTaxInvoiceId'
                element={<SaleTaxInvoiceDetailPage />}
              />
            </>
          ) : (
            <></>
          )}
          {role === 'user' && role !== null ? (
            <>
              <Route path='cart' element={<CartPage />} />
              <Route path='cart_products' element={<DynamicCartPage />} />
              {/* <Route
              path='cart_products'
              element={<ProtectedRoute component={DynamicCartPage} />}
            /> */}
              <Route path='payment' element={<DynamicPaymentPage />} />
              <Route path='order' element={<OrderPage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route
                path='user_order_confirmed_page/:orderId'
                element={<UserOrderConfirmedPage />}
              />
              <Route
                path='user_purchase_order_report'
                element={<UserOrderReportPage />}
              />
              <Route
                path='user_order_detail_page/:orderId'
                element={<UserOrderDetailPage />}
              />
            </>
          ) : (
            <></>
          )}
          <Route path='*' element={<Navigate to='' />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default Router;
