import React from 'react';
import ReactDOM from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorContextProvider from './context/ErrorContext';
import AuthAdminContextProvider from './context/AuthAdminContext';
import AuthContextProvider from './context/AuthContext';
import ReRenderContextProvider from './context/ReRenderContext';
import SearchContextProvider from './context/SearchContext';
import ProductContextProvider from './context/ProductContext';
import DarkThemeContextProvider from './context/DarkThemeContext';
import GoogleMapContextProvider from './context/googleMap/googleMap';
import OrderContextProvider from './context/UserOrderContext';
import OpenCloseComponentContextProvider from './context/OpenCloseComponentContext';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById('root'));
//Uncaught TypeError: Cannot read properties of null (reading 'values') Bug
// disableReactDevTools();
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ErrorContextProvider>
          <ReRenderContextProvider>
            <OpenCloseComponentContextProvider>
              <GoogleMapContextProvider>
                <DarkThemeContextProvider>
                  <SearchContextProvider>
                    <AuthAdminContextProvider>
                      <AuthContextProvider>
                        <OrderContextProvider>
                          <ProductContextProvider>
                            <App />
                          </ProductContextProvider>
                        </OrderContextProvider>
                      </AuthContextProvider>
                    </AuthAdminContextProvider>
                  </SearchContextProvider>
                </DarkThemeContextProvider>
              </GoogleMapContextProvider>
            </OpenCloseComponentContextProvider>
          </ReRenderContextProvider>
        </ErrorContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
