import React, { useEffect, useState, useContext } from 'react';
import { DarkThemeContext } from '../../../context/DarkThemeContext';
import { ProductContext } from '../../../context/ProductContext';
import { AuthContext } from '../../../context/AuthContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from '../../../config/axios';
import { BsList, BsTelephone } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineRoofing } from 'react-icons/md';
import { TbRectangle } from 'react-icons/tb';
import { RiLoginCircleLine } from 'react-icons/ri';
import {
  GiConcreteBag,
  GiBrickWall,
  GiWoodBeam,
  GiScrew,
  GiSwapBag,
  GiWoodenPier,
  GiIBeam,
} from 'react-icons/gi';
import { BiCylinder } from 'react-icons/bi';

function NavBar() {
  const { displayDarkTheme, setDisplayDarkTheme } =
    useContext(DarkThemeContext);
  const navigate = useNavigate();
  // const handleGetAllCategory = async () => {
  //   try {
  //     const res = await axios.get(`/categories/getAllCategory`);
  //     console.log('res?.data?.categories', res?.data?.categories);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const categoryIconArrays = [
    <GiIBeam />,
    <MdOutlineRoofing />,
    <GiWoodenPier />,
    <TbRectangle />,
    <GiSwapBag />,
    <BiCylinder />,
    <GiConcreteBag />,
    <GiWoodBeam />,
    <TbRectangle />,
    <GiBrickWall />,
    <TbRectangle />,
    <GiScrew />,
    <TbRectangle />,
  ];
  const { role } = useContext(AuthContext);
  const {
    searchByProductContext,
    setSearchByProductContext,
    searchTermProductContext,
    setSearchTermProductContext,
  } = useContext(ProductContext);
  const [categoryId, setCategoryId] = useState(0);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [categories, setCategories] = useState([]);
  const handleGetAllCategory = async () => {
    try {
      const res = await axios.get('/categories/getAllCategory');
      // console.log(res.data);
      setCategories(res.data.categories);
      const selectedSubCategoryArray = categories.find(
        (el) => el.id === +categoryId
      );
      // console.log('selectedSubCategoryArray', selectedSubCategoryArray);
      // if (selectedSubCategoryArray) {
      //   setSubCatOptions(selectedSubCategoryArray);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetAllCategory();
  }, [categoryId]);

  const handleDropdownClick = () => {
    setShowCategoryDropdown(!showCategoryDropdown);
    if (!showCategoryDropdown) {
      const scrollAmount = window.innerHeight / 3;
      window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  };
  // console.log('categories', categories);
  // console.log('showCategoryDropdown', showCategoryDropdown);
  return (
    <div
      className={`flex justify-center ${
        displayDarkTheme
          ? 'bg-gray-700'
          : 'bg-gradient-to-r from-gray-500 via-gray-400 to-gray-500'
      }`}
    >
      <div className='w-full h-8 sm:h-9 flex items-center justify-center text-[14px] sm:text-[16px]'>
        <ul className='flex justify-between w-full px-3'>
          {/* <li className='nav-item '>
            <div
              className={`nav-link font-bold cursor-pointer hover:scale-105 ${
                displayDarkTheme ? 'text-white' : 'text-white'
              }`}
              onClick={() => {
                handleGetAllCategory();
              }}
            >
              หมวดหมู่สินค้า
            </div>
          </li> */}
          <div className='dropdown dropdown-hover'>
            <div
              tabIndex={0}
              role='button'
              className='font-bold flex items-center gap-[12px] sm:gap-[60px]'
              onClick={() => {
                setShowCategoryDropdown(
                  (showCategoryDropdown) => !showCategoryDropdown
                );
              }}
            >
              <div className='flex items-center gap-2'>
                <BsList size={25} color='white' />
                <p className='text-white hover:scale-105'>หมวดหมู่สินค้า</p>
              </div>
              <IoIosArrowDown color='white' className='hover:scale-105' />
            </div>
            {showCategoryDropdown === true ? (
              <div className=''>
                <ul
                  tabIndex={0}
                  className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-[279px] sm:w-[305px] h-[525px] sm:h-[563px] md:h-[563px] lg:h-auto overflow-y-scroll'
                >
                  {categories?.map((el, idx) => {
                    return (
                      <div
                        className=''
                        key={idx}
                        onClick={() => {
                          setShowCategoryDropdown(
                            (showCategoryDropdown) => !showCategoryDropdown
                          );
                          window.scrollBy({ top: 200, behavior: 'smooth' });
                        }}
                      >
                        <li className='' key={el?.id}>
                          <div
                            className='flex items-center gap-3 hover:bg-gradient-to-r from-gray-50 to-gray-200'
                            onClick={() => {
                              navigate('/');
                              setSearchByProductContext('categoryName');
                              setSearchTermProductContext(el?.name);
                            }}
                          >
                            <div className='text-gray-700'>
                              {categoryIconArrays[idx]}
                            </div>
                            <div className='hover:text-gray-700 text-gray-700'>
                              {el?.name}
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='flex items-center justify-center'>
            {role === null ? (
              <>
                <div className='px-1'>
                  <li className='nav-item'>
                    <Link
                      to='/login'
                      className={`nav-link font-bold ${
                        displayDarkTheme ? 'text-white' : 'text-white'
                      }`}
                    >
                      <div className='hover:scale-105 lg:pr-2 text-white flex justify-between items-center gap-1'>
                        <RiLoginCircleLine color='white' />
                        <p>เข้าสู่ระบบ</p>
                      </div>
                    </Link>
                  </li>
                </div>
              </>
            ) : (
              <div className=''></div>
            )}
            <div className='px-1'>
              <li className='nav-item'>
                <Link
                  to='/contact'
                  className={`nav-link font-bold ${
                    displayDarkTheme ? 'text-white' : 'text-white'
                  }`}
                >
                  <div className='hover:scale-105 lg:pr-2 text-white flex justify-between items-center gap-1'>
                    <BsTelephone color='white' className='' />
                    <p>ติดต่อ</p>
                  </div>
                </Link>
              </li>
              {/* <li className='nav-item'>
            <Link
              to='/'
              className={`nav-link font-bold ${
                displayDarkTheme ? 'text-white' : 'text-white'
              }`}
            >
              <p className='hover:scale-105'>หน้าแรก</p>
            </Link>
          </li> */}
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
  // return (
  //   <div
  //     className={`flex justify-center ${
  //       displayDarkTheme
  //         ? 'bg-gray-700'
  //         : 'bg-gradient-to-b from-gray-200 to-white '
  //     } `}
  //   >
  //     <div className='w-[1230px]'>
  //       <ul className='flex mt-20 sm:mt-0 md:justify-end gap-5 '>
  //         <li className='nav-item mr-3 my-3 '>
  //           <Link
  //             to='/'
  //             className={`nav-link font-bold   ${
  //               displayDarkTheme ? 'text-white' : 'text-gray-700'
  //             } `}
  //           >
  //             หน้าแรก
  //           </Link>
  //         </li>
  //         <li className='nav-item mr-3 my-3'>
  //           <div
  //             to='/'
  //             className={`nav-link font-bold cursor-pointer   ${
  //               displayDarkTheme ? 'text-white' : 'text-gray-700'
  //             }`}
  //             onClick={() => {
  //               handleGetAllCategory();
  //             }}
  //           >
  //             หมวดหมู่สินค้า
  //           </div>
  //         </li>
  //         <li className='nav-item  mr-3 my-3'>
  //           <Link
  //             to={`/contact`}
  //             className={`nav-link font-bold   ${
  //               displayDarkTheme ? 'text-white' : 'text-gray-700'
  //             }`}
  //           >
  //             ติดต่อ
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   </div>
  // );
}

export default NavBar;
