import axios from '../config/axios';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { createContext, useContext, useEffect, useState } from 'react';
import { ReRenderContext } from '../context/ReRenderContext';
import { getCsrfToken } from '../services/csrfService';
import xss from 'xss';
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '../services/localStorage';
import {
  getAccessTokenFromCookie,
  removeAccessTokenFromCookie,
} from '../services/cookieStorage';
import { API_ENDPOINT_URL } from '../config/env';
const AuthContext = createContext();
function AuthContextProvider({ children }) {
  const { setReRender, reRender } = useContext(ReRenderContext);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [role, setRole] = useState(null);
  const [token, setToken] = useState('');
  const [userIsUnauthorised, setUserIsUnauthorised] = useState(false);
  //-------------------------------------------------------------------
  const [csrfToken, setCsrfToken] = useState('');
  const [isLoadingOtpPage, setIsLoadingOtpPage] = useState(false);
  const [error, setError] = useState(null);
  //-------------------------------------------------------------------
  const [userIsAuthorised, setUserIsAuthorised] = useState(false);
  const [userIsAuthorisedByPhoneNumber, setUserIsAuthorisedByPhoneNumber] =
    useState(false);
  const [otpUsername, setOtpUsername] = useState('');
  const [otpPassword, setOtpPassword] = useState('');
  const [otpPhoneNumber, setOtpPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  // const customAxios = axios.create({ baseURL: API_ENDPOINT_URL });
  // useEffect(() => {
  //   const fetchCsrfToken = async () => {
  //     try {
  //       const response = await axios.get('/auth/frsc/form');
  //       setCsrfToken(response?.data?.csrfToken);
  //     } catch (error) {
  //       console.error('Error fetching CSRF token:', error);
  //     }
  //   };
  //   fetchCsrfToken();
  // }, []);
  const checkAuthStatus = async () => {
    try {
      const response = await axios.get('/auth/frsc/form');
      setCsrfToken(response?.data?.csrfToken);
      // console.log('CSRF token inside', response?.data?.csrfToken);
      const resIsValidRefreshToken = await axios.get(
        '/auth/checkRefreshToken',
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': response?.data?.csrfToken,
          },
        }
      );
      if (resIsValidRefreshToken?.data?.isValid === false) {
        setAuthState({ isAuthenticated: false, user: null });
        // console.log('refreshToken not found');
      }
      const resIsValidAdminRefreshToken = await axios.get(
        '/auth/checkAdminRefreshToken',
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': response?.data?.csrfToken,
          },
        }
      );
      if (resIsValidAdminRefreshToken?.data?.isValid === false) {
        setAuthState({ isAuthenticated: false, user: null });
        // console.log('refreshToken not found');
      }
      // console.log('resIsValidRefreshToken', resIsValidRefreshToken);
      if (resIsValidRefreshToken?.data?.isValid === true) {
        const res = await axios.post(
          '/auth/refreshAccessToken',
          {},
          {
            withCredentials: true,
            headers: {
              'CSRF-Token': response?.data?.csrfToken,
            },
          }
        );
        // console.log('res', res);
        if (res?.status === 200 || res?.status === 201) {
          const resMe = await axios.get('/users/me');
          setUser(resMe?.data?.user);
          setRole(resMe?.data?.role);
          setAuthState({ isAuthenticated: true, user: resMe?.data?.user });
        } else {
          setAuthState({ isAuthenticated: false, user: null });
        }
      }
      if (resIsValidAdminRefreshToken?.data?.isValid === true) {
        const res = await axios.post(
          '/auth/refreshAdminAccessToken',
          {},
          {
            withCredentials: true,
            headers: {
              'CSRF-Token': response?.data?.csrfToken,
            },
          }
        );
        // console.log('res', res);
        if (res?.status === 200 || res?.status === 201) {
          const resAdmin = await axios.get('/admins/admin', {
            withCredentials: true,
            headers: {
              'CSRF-Token': response?.data?.csrfToken,
            },
          });
          setUser(resAdmin?.data?.admin);
          setRole(resAdmin?.data?.role);
          setAuthState({ isAuthenticated: true, user: resAdmin?.data?.admin });
        } else {
          setAuthState({ isAuthenticated: false, user: null });
        }
      }
    } catch (refreshError) {
      console.log('Failed to refresh access token', refreshError);
    }
  };
  useEffect(() => {
    checkAuthStatus();
  }, []);
  // useEffect(() => {
  //   const fetchMe = async () => {
  //     try {
  //       const token = getAccessToken();
  //       if (token) {
  //         const resMe = await axios.get('/users/me');
  //         // console.log('resMe.data.user', resMe.data.user);
  //         setUser(resMe.data.user);
  //         // console.log('resMe.data.role', resMe.data.role);
  //         setRole(resMe.data.role);
  //       }
  //     } catch (err) {
  //       removeAccessToken();
  //       // navigate('/');
  //     }
  //   };
  //   if (token) {
  //     fetchMe();
  //   }
  // }, []);
  // useEffect(() => {
  //   const fetchMe = async () => {
  //     try {
  //       // console.log('userIsAuthorised', userIsAuthorised);
  //       if (userIsAuthorised) {
  //         const resMe = await axios.get('/users/me');
  //         // console.log('resMe.data.user', resMe.data.user);
  //         setUser(resMe.data.user);
  //         // console.log('resMe.data.role', resMe.data.role);
  //         setRole(resMe.data.role);
  //       }
  //     } catch (err) {
  //       setUserIsAuthorised(false);
  //       // navigate('/');
  //       console.log(err);
  //     }
  //   };
  //   if (userIsAuthorised) {
  //     fetchMe();
  //   }
  // }, []);
  //----------------------------------------------------
  // const fetchMe = async () => {
  //   try {
  //     const resMe = await axios.get('/users/me');
  //     setUser(resMe.data.user);
  //     setRole(resMe.data.role);
  //   } catch (err) {
  //     removeAccessTokenFromCookie();
  //   }
  // };
  //----------------------------------------------------
  // useEffect(() => {
  //   fetchMe();
  // }, []);
  useEffect(() => {
    const fetchAdmin = async () => {
      // try {
      //   const token = getAccessToken();
      //   if (token) {
      //     const resAdmin = await axios.get('/admins/admin', {
      //       withCredentials: true,
      //       headers: {
      //         'CSRF-Token': csrfToken,
      //       },
      //     });
      //     // console.log('resAdmin', resAdmin);
      //     setUser(resAdmin.data.admin);
      //     //-------------------------------------------------
      //     // setAdmin(resAdmin.data.admin)
      //     setRole(resAdmin.data.role);
      //   }
      // } catch (err) {
      //   removeAccessToken();
      //   navigate('/');
      // }
    };
    if (token && role === 'admin') {
      fetchAdmin();
    }
  }, []);
  const signUp = async (input) => {
    try {
      const csrfTokenFromService = await getCsrfToken(); // Ensure you have a method to get the CSRF token
      const response = await axios.post('/auth/signup', input, {
        withCredentials: true,
        headers: {
          'CSRF-Token': csrfTokenFromService,
        },
      });
      // setAccessToken(response?.data?.token); //สมัครเสร็จ ลอคอินได้เลย
      // const resMe = await axios.get('/users/me');
      // setUser(resMe?.data?.user);
      if (response?.data) {
        const resMe = await axios.get('/users/me');
        setUser(resMe?.data?.user);
      } else {
        console.error('Signup failed: No data received from server');
      }
    } catch (err) {
      console.log('Sign up failed with error : ', err);
    }
  };
  //-----------------------------------------------------------------
  // useEffect(() => {
  //   const fetchCsrfToken = async () => {
  //     try {
  //       const response = await axios.get('/auth/frsc/form');
  //       setCsrfToken(response?.data?.csrfToken);
  //     } catch (error) {
  //       console.error('Error fetching CSRF token:', error);
  //     }
  //   };
  //   fetchCsrfToken();
  // }, []);
  //-----------------------------------------------------------------
  const contextHandleLogin = async (username, password) => {
    try {
      // console.log(' csrfToken', csrfToken);
      const csrfTokenFromService = await getCsrfToken(); // Ensure you have a method to get the CSRF token
      let sanitizedUsername = xss(username);
      let sanitizedPassword = xss(password);
      const response = await axios.post(
        '/auth/login',
        {
          username: sanitizedUsername,
          password: sanitizedPassword,
        },
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfTokenFromService,
          },
        }
      );
      // console.log('response.data', response.data);
      // setAccessToken(response.data.token);
      // setToken(response.data.token);
      // setRole(response.data.role);
      // console.log({ role: role });
      if (response?.data?.role === 'user') {
        const checkTokenValue = getAccessTokenFromCookie();
        // console.log('checkTokenValue', checkTokenValue);
        // setAccessToken(getAccessTokenFromCookie()); //
        // setToken(getAccessTokenFromCookie());

        setUserIsAuthorised(true);
        setRole(response?.data?.role);
        const resMe = await axios.get('/users/me');
        setAuthState({ isAuthenticated: true, user: resMe?.data?.user });
        // setPassword('');
        // setUsername('');
        // console.log('resMe.data.user', resMe.data.user);
        setUser(resMe?.data?.user);
        // console.log('resMe.data.role', resMe.data.role);
        setRole(resMe?.data?.role);
        navigate('/');
      }
      if (response?.data?.role === 'admin') {
        const sendOtpResponse = await axios.post(
          '/api/otp/send-otp',
          {
            username: sanitizedUsername,
            password: sanitizedPassword,
          },
          {
            withCredentials: true,
            headers: {
              'CSRF-Token': csrfTokenFromService,
            },
          }
        );
        // setPassword('');
        // setUsername('');
        navigate('/otp');
      }
      if (response?.data?.role === 'saleAdmin') {
        // navigate('/order_admin');
        // setAccessToken(response?.data?.token); //
        // setToken(response?.data?.token);
        setRole(response?.data?.role);
        navigate('/');
        const resSaleAdmin = await axios.get('/saleAdmins/saleAdmin');
        // setPassword('');
        // setUsername('');
        setUser(resSaleAdmin?.data?.saleAdmin);
      }
      setReRender((reRender) => !reRender);
    } catch (error) {
      setIsLoadingOtpPage(false);
      if (error.response) {
        // The request was made, but the server responded with an error
        setError(error.response.data.message);
      } else if (error.request) {
        // The request was made, but no response was received
        setError('No response from the server');
      } else {
        // Something happened in setting up the request that triggered an error
        setError('Error setting up the request');
      }
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };
  //-----------------------------------------------------------------
  const login = async (username, password) => {
    // console.log('password', password);
    // const hashedPassword = bcrypt.hashSync(password, 12);
    // console.log('hashedPassword', hashedPassword);
    // const response = await axios.post('/auth/login', {
    //   username,
    //   password: password,
    // });
    // // console.log('response.data', response.data);
    // setAccessToken(response.data.token); //
    // setToken(response.data.token);
    // setRole(response.data.role);
    // // console.log({ role: role });
    // if (response.data.role === 'user') {
    //   const resMe = await axios.get('/users/me');
    //   // console.log('resMe.data.user', resMe.data.user);
    //   setUser(resMe.data.user);
    //   // console.log('resMe.data.role', resMe.data.role);
    //   setRole(resMe.data.role);
    //   if (resMe.data.message === 'Invalid username or password') {
    //     setUserIsUnauthorised(true);
    //   }
    //   navigate('/');
    // }
    // //-------------------------------------------------------------------
    // // const responseFromAdmin = await axios.post('/authAdmin/login', {
    // //   username,
    // //   password,
    // // });
    // // console.log('responseFromAdmin', responseFromAdmin);
    // // setRole(responseFromAdmin.data.role);
    // //-------------------------------------------------------------------
    // if (response.data.role === 'admin') {
    //   // navigate('/order_admin');
    //   navigate('/');
    //   const resAdmin = await axios.get('/admins/admin');
    //   // console.log('resAdmin', resAdmin);
    //   setUser(resAdmin.data.admin);
    // }
    // if (response.data.role === 'saleAdmin') {
    //   // navigate('/order_admin');
    //   navigate('/');
    //   const resSaleAdmin = await axios.get('/saleAdmins/saleAdmin');
    //   // console.log('resSaleAdmin', resSaleAdmin);
    //   setUser(resSaleAdmin.data.saleAdmin);
    // }
    // setReRender((reRender) => !reRender);
    // return response.data.token;
  };
  //----------------------------------------------------------------------------------

  //----------------------------------------------------------------------------------
  const logout = async () => {
    const csrfTokenFromService = await getCsrfToken(); // Ensure you have a method to get the CSRF token
    removeAccessToken(); //
    if (role === 'user') {
      await axios.post(
        '/auth/logout',
        {},
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfTokenFromService,
          },
        }
      );
      setUser(null);
      setRole(null);
    }
    if (role === 'admin') {
      await axios.post(
        '/auth/adminLogout',
        {},
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfTokenFromService,
          },
        }
      );
      setUser(null);
      setRole(null);
    }
    setAuthState({ isAuthenticated: false, user: null });
    setReRender((reRender) => !reRender);

    navigate('/');
  };
  // console.log('user', user);
  // console.log('csrfToken', csrfToken);
  return (
    <AuthContext.Provider
      value={{
        signUp,
        user,
        login,
        contextHandleLogin,
        logout,
        role,
        setRole,
        setUser,
        setUserIsUnauthorised,
        userIsUnauthorised,
        otpUsername,
        setOtpUsername,
        otpPassword,
        setOtpPassword,
        setUserIsAuthorised,
        authState,
        setAuthState,
        csrfToken,
        otpPhoneNumber,
        setOtpPhoneNumber,
        phoneNumber,
        setPhoneNumber,
        setUserIsAuthorisedByPhoneNumber,
        userIsAuthorisedByPhoneNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const ctx = useContext(AuthContext);
  return ctx;
};
export default AuthContextProvider;
export { AuthContext, useAuth };
